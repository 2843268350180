<template>
  <div>
    <report-base
      :reports="listLinkDowloadCSV"
      type="accounting"
      reportBtnWidth="300px"
      containerWidth="600px"
    />
  </div>
</template>

<script>
import ReportBase from './reportBase.vue';

export default {
  name: 'ReportTotal',
  components: {ReportBase},
  data() {
    return {
      listLinkDowloadCSV: [
        {
          title: 'TVOS-GMO請求対応表',
          type: 'TvosGmoCharges',
          reportType: 'general'
        },
        {
          title: 'クレジットカード有効期限別会員データ',
          type: 'CreditCardExpirationReport'
        },
      ],
    };
  },
};
</script>
