<template>
  <div class="t-main mt-3">
    <div class="d-flex">
      <div
        v-for="item in datePicker"
        :key="item.id"
        class="d-flex align-center mb-4"
      >
        <v-menu
          v-model="menuDatePicker[datePicker.indexOf(item)]"
          :close-on-content-click="false"
          :nudge-right="40"
          min-width="auto"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <label class="text--small mr-1">{{
                item.id === 1 ? $t('commons.start') : $t('commons.end')
              }}</label>
            <DatePickerComponent
              type="btn"
              :date="item.date"
              className="bg--white mr-8"
              :attr="{
                'small': true,
                'outlined': true,
              }"
              :action="on"
              @reset="item.date = null"
              :hasBtnResetDatePicker="false"
              :title="formatDate(item.date)"
            ></DatePickerComponent>
          </template>

          <!-- Date from -->
          <v-date-picker
            v-if="item.id === 1"
            v-model="item.date"
            :first-day-of-week="0"
            class="v-date-picker-custom"
            :locale="$i18n.locale"
            scrollable
            @input="menuDatePicker[datePicker.indexOf(item)] = false"
            @change="setDateFrom = $event"
          ></v-date-picker>
          <!-- Date to -->
          <v-date-picker
            v-else
            v-model="item.date"
            :min="fromDate"
            class="v-date-picker-custom"
            :locale="$i18n.locale"
            scrollable
            @input="menuDatePicker[datePicker.indexOf(item)] = false"
          ></v-date-picker>
        </v-menu>
      </div>
    </div>
    <p class="mb-1" v-if="needsDateNotice">
      {{ $t('report.notice') }}
    </p>
    <p>{{ $t('report.dowloadCSVNotice') }}</p>

    <v-row class="mt-1" :style="btnRowStyle">
      <v-col
        v-for="item in reports"
        :key="item.title"
        :style="btnStyle"
      >
        <v-btn
          outlined
          block
          color="#1873D9"
          @click="generate(item)"
          >{{ item.title }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DatePickerComponent from '@/components/Input/DatePicker.vue';
import { nextOrPrevDay, getISODate } from '@/utils/dateUtil';
import { formatDate } from '@/constants/functions'
import { mapActions } from 'vuex';

export default {
  name: 'ReportBase',
  props: {
    reports: Array,
    type: String,
    reportBtnWidth: String,
    containerWidth: String,
  },
  data() {
    return {
      menuDatePicker: [],
      datePicker: [
        {
          id: 1,
          date: this.nextOrPrevDay(getISODate(new Date()), -1),
        },
        { id: 2, date: getISODate(new Date()) },
      ],
    };
  },
  components: {
    DatePickerComponent,
  },
  computed: {
    fromDate() { return this.datePicker[0].date },
    toDate: {
      get() {return this.datePicker[1].date },
      set(v) { this.datePicker[1].date = v},
    } ,
    needsDateNotice () {
      return this.type === 'general'
    },
    btnStyle () {
      return {
        minWidth: this.reportBtnWidth || '250px',
        maxWidth: this.reportBtnWidth || '250px',
      }
    },
    btnRowStyle () {
      return {
        width: this.containerWidth || '1000px'
      }
    }
  },
  watch: {
    'datePicker.0.date': {
      handler (newFromDate) {
        if (newFromDate > this.toDate) {
          this.toDate = newFromDate
        }
      }
    }
  },
  methods: {
    generate (item) {
      const type = item.reportType ?? this.type
      if (type === 'accounting') {
        return this.crmAccountingReport({
          accountingType: item.type,
          fromDate: this.datePicker[0].date,
          toDate: this.datePicker[1].date
        })
      } else if (type === 'general') {
        return this.crmGeneralReport({
          generalType: item.type,
          fromDate: this.datePicker[0].date,
          toDate: this.datePicker[1].date,
        })
      }
    },
    ...mapActions(['crmGeneralReport', 'crmAccountingReport']),
    nextOrPrevDay,
    formatDate(date) {
      return date ? formatDate(date) : '';
    },
  }
};
</script>

<style lang="scss" scoped>
p {
  color: #212121;
  font-size: 18px;
}
.mdi-calendar-month {
  color: #333333 !important;
}
::v-deep {
  .text--small {
    color: #000000 !important;
    font-weight: 500;
  }
  .v-btn.v-size--small {
    font-size: 10px !important;
    height: 24px !important;
  }
  // .v-btn:not(.v-btn--round).v-size--default{
  //   padding: 20px !important;
  // }
  .v-btn__content {
    display: -webkit-box !important;
  }
}

</style>