<template>
  <div>
    <report-base 
      :reports="listLinkDowloadCSV"
      type="accounting"
      reportBtnWidth="300px"
      containerWidth="600px"
    />
  </div>
</template>

<script>
import ReportBase from './reportBase.vue';

export default {
  name: 'ReportTotal',
  components: {ReportBase},
  data() {
    return {
      listLinkDowloadCSV: [
        {
          title: 'TOPカード請求データ',
          type: 'TOPInvoiceData'
        },
        {
          title: '新収益基準データ（SP）',
          type: 'SPAccountingReport'
        },
        {
          title: '新収益基準データ（PW）',
          type: 'PWAccountingReport'
        },
        {
          title: '新収益基準データ（FP）',
          type: 'FPAccountingReport'
        },
        {
          title: '新収益基準データ（MW）',
          type: 'MWAccountingReport'
        },
        {
          title: '新収益基準データ（法人チケット）',
          type: 'BookingTicketAccountingReport'
        },
        {
          title: '新収益基準データ（VM）',
          type: 'VMAccountingReport'
        },
        {
          title: '新収益基準データ（TVP）',
          type: 'TVPAccountingReport'
        },
        {
          title: '新収益基準データ（商品券棚卸）',
          type: 'RemainingPointReport'
        },
        {
          title: '新収益基準データ（チケット棚卸）',
          type: 'RemainingTicketReport'
        },
        {
          title: '新収益基準データ（TVP棚卸）',
          type: 'RemainingTVPReport'
        },
        {
          title: '交換手数料一覧',
          type: 'MwExchangeFeeReport'
        },
        {
          title: 'SP・FP追加購入一覧',
          type: 'ContractPointPurchaseReport'
        },
        {
          title: 'その他請求一覧',
          type: 'OtherClientChargeReport'
        },
        {
          title: '年会費一覧',
          type: 'AnnualFeeReport'
        },
      ],
    };
  },
};
</script>
