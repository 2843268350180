<template>
  <div>
    <report-base
      :reports="listLinkDowloadCSV"
      type="general"
    />
  </div>
</template>

<script>
import ReportBase from './reportBase.vue';

export default {
  name: 'Report',
  data() {
    return {
      listLinkDowloadCSV: [
        {
          title: '契約予定一覧',
          type: 'ExpectedContractList',
        },
        {
          title: '広告施策一覧',
          type: 'CampaignSummary',
        },
        {
          title: '広告施策反応別詳細一覧',
          type: 'CampaignList',
        },
        {
          title: '広告施策別詳細一覧',
          type: 'CampaignDetail',
        },
        {
          title: 'VM契約数一覧',
          type: 'MonthlyContracts',
        },
        {
          title: 'VS契約数一覧',
          type: 'VSMonthlyContracts',
        },
        {
          title: '検討離脱理由',
          type: 'NegotiationExitReason',
        },
        {
          title: '体験宿泊一覧',
          type: 'MonthlyTrialStay',
        },
        {
          title: '対面一覧',
          type: 'ContractByFaceToFace',
        },
        {
          title: '契約特典一覧',
          type: 'SignupBonusSummary',
        },
        {
          title: '興味のある施設一覧',
          type: 'FacilitiesInterested',
        },
        {
          title: '施設利用実績一覧',
          type: 'FacilityOccupancyRate',
        },
        {
          title: '譲渡、相続、解約一覧',
          type: 'ContractTermination',
        },
        {
          title: '退会一覧',
          type: 'MembershipTermination',
        },
        {
          title: '権利利用状況一覧',
          type: 'ProductUsage',
        },
        {
          title: 'ギフトチケット申込一覧',
          type: 'PointExchangeOrders',
        },
        {
          title: '施設利用実績一覧（ブロック除く）',
          type: 'FacilityOccupancyRateExcludeBlocks',
        },
      ],
    };
  },
  components: {
    ReportBase,
  },
};
</script>
