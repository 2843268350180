<template>
  <div>
    <v-card-title v-once class="mt-n5 page-title-sub card-title">
      <v-row style="background-color: #F8F8F8">
        <v-col :cols="10" class="pb-0">
          <h1
            class="mx-6 my-4 page-title-list"
            style="text-align: left;"
          >
            帳票・CSV
          </h1>
        </v-col>
      </v-row>
    </v-card-title>
    <template>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="#13ACE0"></v-tabs-slider>
        <v-tab v-for="item in filteredTabs" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in filteredTabs" :key="item">
          <report v-if="tab === 0"></report>
          <report-total v-if="tab === 1"></report-total>
          <report-credit-card v-if="tab === 2"></report-credit-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </div>
</template>

<script>
import Report from './report.vue'
import ReportCreditCard from './reportCreditCard.vue'
import ReportTotal from './reportTotal.vue'

  export default {

    name: 'Dashboard',
    components: {
        Report,
        ReportTotal,
        ReportCreditCard,
    },
    data(){
      return {
        tab:0,
        tabNames: ['一覧', '会計', 'クレジットカード関連']
      }
    },
    computed: {
      filteredTabs() {
        if (this.$store.getters.roleAdminUserCurrent) {
          return this.tabNames
        } else if (this.$store.getters.hasReadPermission('/report/credit-card')) {
          return this.tabNames
        } else {
          return this.tabNames.slice(0, 2)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-tab{
    color: #13ACE0 !important;
  }
</style>
